import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import englishTranslations from '../../public/locales/en/translation.json'
import spanishTranslations from '../../public/locales/es/translation.json'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: { translation: englishTranslations },
      es: { translation: spanishTranslations },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
